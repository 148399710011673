/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import InfoIconSvg from '../../../images/info-sign.svg'
import { useDeleteMember } from '../../../utils/api'
/**
 *
 * ANCHOR Page
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const MyAccountDeleltMemberModal = (props) => {
  // ANCHOR props
  const {
    openModal = true,
    setOpenModal = NOOP,
    data
  } = props

  console.log('data', data)

  // ANCHOR query
  const deleteMemberMutation = useDeleteMember()

  const [isNoId, setIsNoId] = React.useState(false)
  // ANCHOR functions
  const onHide = () => {
    setOpenModal(false)
    setIsNoId(false)
    deleteMemberMutation.reset()
  }
  const internalOnSave = () => {
    deleteListings()
  }

  /**
   * Upload the product manifest file.
   */
  const deleteListings = () => {
    if (!data.id) {
      setIsNoId(true)
    } else {
      deleteMemberMutation.mutate([{ id: data.id }])
    }
  }

  return (
    <>
      <Modal show={openModal} onHide={onHide}>
        <Modal.Body>
        <button type="button" className="close" onClick={onHide}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
          <h5 className="m-0 mb-3">
            <img src={InfoIconSvg} className="mr-3" />
            Delete user?
          </h5>

          <p>
            User will be removed from the company.
          </p>

          {/* buttons */}

          <div className="d-flex justify-content-between mt-5">
            {deleteMemberMutation.isSuccess
              ? <Button
                  type="button"
                  onClick={onHide}
                  variant="outline-primary"
                >
                  Close
                </Button>

              : (
              <Button type="button" onClick={onHide} variant="outline-primary">
                Cancel
              </Button>
                )}

            <Button
              type="button"
              onClick={internalOnSave}
              variant="primary"
              disabled={deleteMemberMutation.isSuccess}
            >
              {deleteMemberMutation.isLoading
                ? (
                <Spinner />
                  )
                : (
                <span>Delete</span>
                  )}
            </Button>
          </div>

          {deleteMemberMutation.isSuccess && (
            <Alert variant="success" className="mb-0 mt-3 text-center">
              Delete successful!
            </Alert>
          )}
          {deleteMemberMutation.isError && (
            <Alert variant="danger" className="mb-0 mt-3 text-center">
              {'Error deleting accounts. ' +
                deleteMemberMutation.error.message}
            </Alert>
          )}
          {isNoId && (
            <Alert variant="danger" className="mb-0 mt-3 text-center">
              {'This user does not have an id yet'}
            </Alert>
          )}

        </Modal.Body>
      </Modal>
    </>
  )
}

export default MyAccountDeleltMemberModal
